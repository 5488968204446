<template>
  <div class="col-8 mx-auto justify-content-center">
    <h3>Dodaj instalację</h3>
    <form class="add-form bg-light border" @submit.prevent>
      <small>Wybierz kontrahenta</small>
      <v-select
        v-model="contractor"
        label="label"
        :options="contractors"
        placeholder="Wybierz kontrahenta"
      ></v-select>
      <small>Numer seryjny urządzenia</small>
      <input
        v-model="serialNumber"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Numer seryjny urządzenia"
      />
      <!-- <small>Ilość czynnika</small>
      <input
        v-model="amountOfRefrigerant"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Ilość czynnika"
      /> -->
      <small>Wybór brygady</small>
      <v-select
        v-model="squad"
        :options="['Brygada 1', 'Brygada 2', 'Brygada 3']"
        placeholder="Brygada montazowa"
      >
      </v-select>
      <small>Data montazu</small>
      <input
        v-model="date"
        type="date"
        class="form-control mt-1 mb-1"
        placeholder="Data montazu"
      />
      <small>Uwagi</small>
      <!-- <textarea
        v-model="notice"
        type="text"
        class="form-control mb-1"
        placeholder="Uwagi"
      /> -->
      <vue-editor v-model="notice"></vue-editor>
      <small>Przegląd</small>
      <div class="row mb-3">
        <div class="col-12">
          <input
            v-model="service"
            type="date"
            class="form-control mt-1 mb-1"
            placeholder="Data serwisu"
          />
        </div>
      </div>
      <button
        style="width: 100%; height: 65px"
        class="btn btn-info btn-sm mt-1"
        @click="addInstallation()"
      >
        Dodaj instalacje
      </button>
    </form>
  </div>
</template>
<script>
import router from "../router/index";
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
  data() {
    return {
      contractor: "",
      serialNumber: "",
      amountOfRefrigerant: "",
      notice: "",
      date: "",
      overview: "",
      service: "",
      squad: "",
    };
  },
  computed: {
    ...mapState(["userProfile", "contractors"]),
  },
  components: {
    VueEditor,
  },
  methods: {
    addInstallation() {
      console.log("contractor");
      if (this.contractor) {
        this.$store
          .dispatch("createInstallation", {
            contractor: this.contractor,
            serialNumber: this.serialNumber,
            // amountOfRefrigerant:this.amountOfRefrigerant,
            date: this.date,
            notice: this.notice,
            overview: this.overview,
            service: this.service,
            squad: this.squad,
          })
          .then(
            (this.contractor = ""),
            (this.serialNumber = ""),
            (this.amountOfRefrigerant = ""),
            (this.notice = ""),
            (this.date = ""),
            (this.overview = ""),
            (this.service = ""),
            (this.squad = ""),
            alert("Instalacja została dodana")
          );
        router.push("/");
      } else {
        alert("brak kontrahenta");
      }
    },
  },
};
</script>
